import { useContext, useEffect, useMemo, useRef } from 'react';
import { useBeforeMount } from '@24hr/react-hooks';
import { HUMANY_FAQ_QUERY_KEY, humanyFaqQuery } from '@/queries/humany';
import { AppContext } from '@/contexts/app-context';
import { useHumany } from '@/hooks/use-humany';
import { TELIA_SCRIPT_ID, addScriptTag } from '../../page/components/humany-chatbot';

declare global {
    interface Window {
        humany: {
            widgets: {
                find: (name: string) => {
                    activate: () => unknown;
                    deactivate: () => unknown;
                    invoke: (fn: string, data?: Record<string, unknown>) => unknown;
                    state: string;
                };
            };
        };
    }
}

export type OneWidgetFaqProps = {
    widgetName: string;
    widgetType: string;
};

function OneWidgetFaq({ widgetSlug = 'kundservice', widgetType = 'private' }) {
    const context = useContext(AppContext);
    const ref = useRef<HTMLDivElement>(null);
    const { path, baseUrl, humanyData } = useHumany({ widgetType, widgetSlug });

    useBeforeMount(() => {
        context.prefetch({
            queryKey: [HUMANY_FAQ_QUERY_KEY, widgetSlug, path || '/'],
            queryFn: () => humanyFaqQuery(widgetSlug, path, baseUrl),
        });
    });

    async function activateWidget(widgetName: string, ref: React.RefObject<HTMLDivElement> | null) {
        try {
            const widget = window?.humany?.widgets?.find(widgetName);
            if (!widget) {
                throw new Error(`Widget ${widget} not found :(`);
            }

            await widget?.activate();
            await widget?.invoke('detach');
            await widget?.invoke('attach', {
                widgetDOMElement: ref?.current,
                withRenderState: 'open',
            });
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (document.getElementById(TELIA_SCRIPT_ID)) {
            activateWidget(widgetSlug, ref);
        } else {
            addScriptTag({ widgetName: widgetSlug, activate: false });
        }
    }, [widgetSlug, widgetType]);

    const id = useMemo(() => `humany_${widgetSlug}`, [widgetSlug]);

    return (
        <div className="humany-one-widget-container" data-component="one-widget-faq">
            {humanyData.data?.body ? (
                <div
                    id={id}
                    dangerouslySetInnerHTML={{
                        __html: JSON.parse(humanyData.data.body),
                    }}
                />
            ) : (
                <div className="r-flex r-justify-center" id={id} ref={ref}>
                    <div className="r-pt-8">
                        <svg className="r-h-12 r-w-12 r-animate-spin r-text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="r-opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                            <path
                                className="r-opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            />
                        </svg>
                    </div>
                </div>
            )}
        </div>
    );
}

export default OneWidgetFaq;
