import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { HUMANY_SEARCH_QUERY_KEY, humanySearchQuery } from '@/queries/search';
import { useGlobalQueries } from './use-global-queries';
import { useMemo } from 'react';

export function useHumanySearch(searchValue: string) {
    const { getSetting, getMarketType } = useGlobalQueries();

    const humanyApi = useMemo(() => {
        const humanySettings = getSetting('humany_settings');
        return getMarketType() === 'corporate' && humanySettings?.humany_enable_search_results_business && humanySettings?.humany_search_api_business
            ? humanySettings?.humany_search_api_business
            : humanySettings?.humany_search_api;
    }, [getMarketType, getSetting]);

    const humanySearchResults = useQuery({
        queryKey: [HUMANY_SEARCH_QUERY_KEY, humanyApi, searchValue],
        queryFn: () => humanySearchQuery(humanyApi, searchValue),
        placeholderData: keepPreviousData,
        enabled: !!humanyApi && !!searchValue,
    });

    return {
        humanySearchResults,
    };
}
