import { createRef, useContext, useEffect, useMemo, useState } from 'react';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useLocalization } from '@/hooks/use-localization';
import { HUMANY_SEARCH_QUERY_KEY, SEARCH_QUERY_KEY, humanySearchQuery, searchQuery } from '@/queries/search';
import { useGlobalQueries } from '@/hooks/use-global-queries';
import { HumanySearchResults } from './components/humany-search-results';
import { SearchResults } from './components/search-results';
import { AppContext } from '@/contexts/app-context';

export const Search = ({ isSearchOpen, close }: { isSearchOpen: boolean; close: () => void }) => {
    const context = useContext(AppContext);
    const { localize } = useLocalization();
    const { getSetting, pageQuery, getMarketType } = useGlobalQueries();

    const humanyApi = useMemo(() => {
        const humanySettings = getSetting('humany_settings');
        return getMarketType() === 'corporate' && humanySettings?.humany_enable_search_results_business && humanySettings?.humany_search_api_business
            ? humanySettings?.humany_search_api_business
            : humanySettings?.humany_search_api;
    }, [getMarketType, getSetting]);

    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(1);

    const inputRef = createRef<HTMLInputElement>();

    useEffect(() => {
        if (isSearchOpen) {
            inputRef.current?.focus();
        }
    }, [isSearchOpen]);

    useEffect(() => {
        if (!searchTerm) {
            inputRef.current?.focus();
        }
    }, [searchTerm]);

    const searchData = useQuery({
        queryKey: [SEARCH_QUERY_KEY, searchTerm, page],
        queryFn: () => searchQuery(searchTerm, page, context, pageQuery.data?.market_type === 'cluster'),
        placeholderData: keepPreviousData,
        enabled: !!searchTerm,
    });

    const humanyData = useQuery({
        queryKey: [HUMANY_SEARCH_QUERY_KEY, humanyApi, searchTerm],
        queryFn: () => humanySearchQuery(humanyApi, searchTerm),
        placeholderData: keepPreviousData,
        enabled: !!humanyApi && !!searchTerm,
    });

    if (!isSearchOpen) {
        return null;
    }

    return (
        <div
            data-component="search"
            className="scrollbar-hide r-relative r-right-0 r-top-0 r-min-h-screen r-overflow-scroll r-bg-white r-px-16 r-py-4"
        >
            <div className="scrollbar-hide r-overflow-scroll">
                <div className="r-mb-8 r-flex r-justify-end">
                    <button className="" role="button" onClick={() => close()}>
                        <img src="/assets/icons/close.svg" alt={localize('close')} />
                    </button>
                </div>

                <div className="r-relative">
                    <img
                        className="r-absolute r-left-4 r-top-[18px] r-max-w-[20px] r-opacity-50"
                        src="/assets/icons/search.svg"
                        alt=""
                        width={20}
                        height={20}
                    />
                    <input
                        ref={inputRef}
                        className="r-br-2 r-mb-16 r-w-full r-rounded-[2rem] r-border r-border-gray-400 r-py-4 r-pl-12 r-pr-4"
                        placeholder={localize('search')}
                        value={searchTerm}
                        onChange={({ target }) => setSearchTerm(target.value)}
                    />
                    {searchTerm ? (
                        <button className="r-absolute r-right-4 r-top-[12px]" onClick={() => setSearchTerm('')} role="button">
                            <img src="/assets/icons/close-reverse.svg" alt={localize('close')} />
                        </button>
                    ) : null}
                </div>

                {humanyApi && humanyData.isSuccess ? <HumanySearchResults close={close} data={humanyData} searchTerm={searchTerm} /> : null}
                {searchData.isSuccess ? (
                    <SearchResults close={close} data={searchData} searchTerm={searchTerm} page={page} setPage={setPage} />
                ) : null}
            </div>
        </div>
    );
};
