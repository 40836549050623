import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useBeforeMount } from '@24hr/react-hooks';
import clsx from 'clsx';
import { AppContext } from '@/contexts/app-context';
import { useSupremeCards } from '@/hooks/use-supreme-cards';
import { SUPREME_CARDS_QUERY_KEY, getSupremeCards } from '@/queries/supreme-cards';
import { Card } from './card';
import { BenefitsMobile } from './benefits-mobile';
import { Button } from '../../typography/button/button';
import { useLocalization } from '@/hooks/use-localization';

export type CardComparison = {
    subtext: string;
    title: string;
    cards: {
        name: string;
        slug: string;
        id: number;
    }[];
};

export type CardCtaLink = {
    url: string;
    title: string;
    target?: string;
};

export type CardComparisonProps = {
    cardComparisonData: CardComparison[];
    hideCardImages?: boolean;
    newLayout?: boolean;
    ctaLink?: CardCtaLink;
    hiddenCards?: number[];
};

export const CardComparison = ({ cardComparisonData, ctaLink, hideCardImages = false, hiddenCards = [] }: CardComparisonProps) => {
    const context = useContext(AppContext);
    const { localize } = useLocalization();
    const {
        supremeCards: { data = [] },
    } = useSupremeCards();

    const headerContainerRef = useRef(null);
    const contentContainerRef = useRef(null);
    const [isHeaderVisible, setIsHeaderVisible] = useState(false);
    const [isContentVisible, setIsContentVisible] = useState(false);

    useBeforeMount(() => {
        context.prefetch({ queryKey: [SUPREME_CARDS_QUERY_KEY], queryFn: () => getSupremeCards(context) });
    });

    const orderedCardComparisonData = useMemo(
        () => cardComparisonData?.sort((cardA, cardB) => (cardA.cards.length > cardB.cards.length ? -1 : 1) || []),
        [cardComparisonData]
    );

    const sortedCards = useMemo(() => {
        const sortedCardBenefits: { [key: string]: number } = {};

        data?.forEach(({ post_title: title }) => {
            sortedCardBenefits[title] = 0;
        });

        cardComparisonData?.forEach(card => {
            card.cards.forEach(({ name }) => {
                if (name in sortedCardBenefits) {
                    sortedCardBenefits[name]++;
                }
            });
        });

        return (
            data
                ?.filter(card => {
                    if (!hiddenCards?.length) {
                        return true;
                    }

                    return !hiddenCards.includes(card.id);
                })
                ?.sort((cardA, cardB) => sortedCardBenefits[cardA.post_title] - sortedCardBenefits[cardB.post_title]) || []
        );
    }, [data, cardComparisonData, hiddenCards]);

    useEffect(() => {
        const headerObserver = new IntersectionObserver(
            ([entry]) => {
                setIsHeaderVisible(entry.isIntersecting);
            },
            {
                threshold: 0.8,
            }
        );
        const contentObserver = new IntersectionObserver(
            ([entry]) => {
                setIsContentVisible(entry.isIntersecting);
            },
            {
                threshold: 0.1,
            }
        );

        if (headerContainerRef?.current) {
            headerObserver.observe(headerContainerRef.current);
        }
        if (contentContainerRef?.current) {
            contentObserver.observe(contentContainerRef.current);
        }
        return () => {
            if (headerContainerRef.current) {
                headerObserver.unobserve(headerContainerRef.current);
            }
            if (contentContainerRef.current) {
                contentObserver.unobserve(contentContainerRef.current);
            }
        };
    }, [headerContainerRef, contentContainerRef]);

    if (!data) {
        return null;
    }

    return (
        <div
            data-component="card-comparison"
            className="r-flex r-flex-col r-justify-between r-bg-white r-p-4 max-md:-r-mx-4 md:r-rounded-2xl md:r-bg-opacity-95 md:r-p-8 md:r-pb-10 md:r-shadow-lg"
        >
            <div ref={headerContainerRef} id="header-container-ref" className="r-flex r-gap-6">
                {sortedCards.map((card, index) => (
                    <Card
                        key={index}
                        card={card}
                        ctaLink={ctaLink}
                        hideCardImages={hideCardImages}
                        applyText={ctaLink?.title || 'Apply'}
                        cardComparisonData={orderedCardComparisonData}
                    />
                ))}
            </div>
            <div
                className={clsx('r-hidden', {
                    'max-md:r-fixed max-md:r-left-0 max-md:r-right-0 max-md:r-top-24 max-md:r-flex max-md:r-gap-6 max-md:r-bg-white max-md:r-px-3 max-md:r-pb-4 max-md:r-shadow-lg md:r-gap-12':
                        isContentVisible && !isHeaderVisible,
                })}
            >
                {sortedCards.map((card, index) => (
                    <Card
                        key={index}
                        card={card}
                        hideCardImages
                        ctaLink={ctaLink}
                        applyText={ctaLink?.title || 'Apply'}
                        cardComparisonData={orderedCardComparisonData}
                    />
                ))}
            </div>
            <div className="r-space-y-6 r-pb-2 md:r-hidden" ref={contentContainerRef} id="content-container-ref">
                <BenefitsMobile cards={sortedCards} cardComparisonData={orderedCardComparisonData} />
                <div className="r-flex r-justify-around r-space-x-4 r-px-2 md:r-space-x-0 md:r-px-0">
                    {sortedCards.map((card, index) => (
                        <Button key={index} type="secondaryBorder" size="small" to={card.comparison_link?.url}>
                            {localize('read-more')}
                        </Button>
                    ))}
                </div>
            </div>
        </div>
    );
};
