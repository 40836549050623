import { Helmet } from 'react-helmet';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useGlobalQueries } from '@/hooks/use-global-queries';
import { AppContext } from '@/contexts/app-context';
import { getImageUrl } from '@/utils/images';
import { removeEnclosingSlashes } from '@/utils/strings';

export const MetaTags = () => {
    const context = useContext(AppContext);
    const { pathname } = useLocation();
    const { pageQuery, getSetting } = useGlobalQueries();

    if (!pageQuery.data) {
        return null;
    }

    const getPageTitle = () => pageQuery.data?.page_title || pageQuery.data?.post_title || 'Resurs Bank';
    const ogImage = getImageUrl(pageQuery.data?.og_image?.src || pageQuery.data?.featured_image?.src);

    const getCanonicalUrl = (defaultPathname: string = '') => {
        const humanySettings = getSetting('humany_settings');
        const url = pageQuery.data?.canonical_url || defaultPathname;
        const normalizedPathname = `/${removeEnclosingSlashes(pathname)}`;
        const humanyPage = humanySettings?.humany_page_link ? `/${removeEnclosingSlashes(humanySettings?.humany_page_link)}` : null;
        const humanyBusinessPage = humanySettings?.humany_page_link_business
            ? `/${removeEnclosingSlashes(humanySettings?.humany_page_link_business)}`
            : null;

        if (humanyPage && normalizedPathname.startsWith(humanyPage)) {
            return `${context.url}${normalizedPathname}`;
        }

        if (humanyBusinessPage && normalizedPathname.startsWith(humanyBusinessPage)) {
            return `${context.url}${normalizedPathname}`;
        }

        return url ? `${context.url}/${removeEnclosingSlashes(url)}` : `${context.url}/${removeEnclosingSlashes(pathname)}`;
    };

    return (
        <Helmet>
            <title>{getPageTitle()}</title>
            <link rel="canonical" href={getCanonicalUrl()} />
            <link rel="alternate" hrefLang={context.language} href={getCanonicalUrl(pathname)} />
            {pageQuery.data?.meta_title ? <meta name="title" content={pageQuery.data?.meta_title} /> : null}
            {pageQuery.data?.meta_description ? <meta name="description" content={pageQuery.data?.meta_description} /> : null}

            <meta property="og:type" content="website" />
            <meta property="og:url" content={getCanonicalUrl()} />

            {pageQuery.data?.og_title || pageQuery.data?.page_title ? (
                <meta property="og:title" content={pageQuery.data?.og_title || pageQuery.data?.page_title} />
            ) : null}
            {pageQuery.data?.og_description || pageQuery.data?.meta_description ? (
                <meta property="og:description" content={pageQuery.data?.og_description || pageQuery.data?.meta_description} />
            ) : null}
            {ogImage ? <meta property="og:image" content={ogImage} /> : null}
        </Helmet>
    );
};
