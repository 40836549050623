import { ApiContextType } from '@/server/api-fetch';
import { PageType } from './page';
import { setDataLayer } from '@/hooks/use-gtm';

export const SEARCH_QUERY_KEY = 'search';
export const HUMANY_SEARCH_QUERY_KEY = 'search';

export type SearchResult = PageType & {
    _formatted: PageType & {
        post_content: TrustedHTML;
    };
};

export type SearchResponse = {
    hits: SearchResult[];
    nbHits: number;
    exhaustiveNbHits: boolean;
    limit: number;
    offset: number;
    processingTimeMs: number;
};

export type HumanySearchResponse = {
    TotalMatches: number;
    Matches: {
        Id: number;
        Modified: string;
        RelativeUrl: string;
        Title: string;
        Type: string;
    }[];
};

export const searchQuery = async (searchTerm: string, page = 1, context: ApiContextType, isClusterPage = false) => {
    const index = `${context.siteId}-${context.role}`;

    // @TODO: This is not currently implemented in the old FE so I'm choosing to
    // leave this unimplemented for now @richard.sweeney
    if (isClusterPage) {
        // const language = state.localization.currentLanguage;
        // const role = state.settings.role || 'draft';
        // rootPath = `&rootPath=${state.pages.current.match(/^[a-z-]+/)[0]}`;
        // index = `${role}-single-cluster-document-${language}`;
    }

    try {
        setDataLayer({ action: 'search', category: 'search', label: searchTerm });

        const host = typeof window === 'undefined' ? 'http://localhost' : '';
        const results = await fetch(`${host}/api/search/${index}/${page}?q=${encodeURIComponent(searchTerm)}`);
        const data = (await results.json()) as SearchResponse;
        return data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const humanySearchQuery = async (api?: string | null, searchTerm = '') => {
    try {
        if (!api) {
            throw new Error(`${api} is not defined`);
        }

        const host = typeof window === 'undefined' ? 'http://localhost' : '';

        const response = await fetch(`${host}/api/humany-search/${api}?s=${searchTerm}`);
        const data = (await response.json()) as {
            status: string;
            data: HumanySearchResponse;
        };

        if (data.status !== 'ok') {
            throw new Error('Data fetching error');
        }

        return data.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};
