import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { SearchResult } from '@/queries/search';
import { useLocalization } from '@/hooks/use-localization';
import { useGlobalQueries } from '@/hooks/use-global-queries';
import { SearchResultsText } from '../../../search/components/search-results-text';
import { useMemo } from 'react';

type HumanyMatch = {
    Id: number;
    Modified: string;
    RelativeUrl: string;
    Title: string;
    Type: string;
};

type SearchResultsProps = {
    setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
    searchValue: string;
    humanyMatches: HumanyMatch[];
    searchHits: SearchResult[];
    totalHumanyMatches: number;
    totalSearchHits: number;
    page: number;
    setPage: React.Dispatch<React.SetStateAction<number>>;
};

export const SearchResults = ({
    humanyMatches,
    totalHumanyMatches,
    searchValue,
    setShowMenu,
    searchHits,
    totalSearchHits,
    page,
    setPage,
}: SearchResultsProps) => {
    const formatPermalink = (permalink: string) => permalink.replace('/cluster_page', '').substring(1);
    const { getSetting, getMarketType } = useGlobalQueries();
    const { localize } = useLocalization();

    const pageLink = useMemo(() => {
        const humanySettings = getSetting('humany_settings');

        return getMarketType() === 'corporate' && humanySettings?.humany_enable_search_results_business && humanySettings?.humany_page_link_business
            ? humanySettings?.humany_page_link_business
            : humanySettings?.humany_page_link;
    }, [getMarketType, getSetting]);

    const getFullUrl = (permalink: string) => {
        const siteUrl = window?.location?.origin || 'http://localhost';
        return permalink ? `${siteUrl}${permalink}` : permalink;
    };

    const renderButton = (_: string, arrayIndex: number) => {
        const index = arrayIndex + 1;

        return (
            <button
                key={`pagination-${index}`}
                disabled={page === index}
                onClick={() => setPage(index)}
                className={clsx('r-flex r-h-8 r-w-8 r-items-center r-justify-center r-rounded-full', {
                    'r-bg-slate-300': page !== index,
                    'r-bg-blue-100': page === index,
                })}
            >
                {index}
            </button>
        );
    };

    const numPages = Math.ceil(totalSearchHits / 10);

    return (
        <div className="r-p-3">
            <div>
                {humanyMatches.length ? (
                    <p className="r-pb-2">
                        <SearchResultsText t10n="search-humany-result-intro" searchValue={searchValue} />
                    </p>
                ) : (
                    <p className="r-pb-2">
                        <SearchResultsText t10n="search-humany-no-results" searchValue={searchValue} />
                    </p>
                )}
                {humanyMatches?.map((item: HumanyMatch, index: number) => (
                    <div key={index} className="r-flex r-items-center r-py-1 r-text-[#008282]">
                        <p className="r-mr-3 r-text-xl">&gt;</p>
                        <Link
                            onClick={() => setShowMenu(false)}
                            reloadDocument
                            to={`${pageLink}${item.RelativeUrl.replace(/^(\d+)/, 'g$1').replace('/', '-')}`}
                            className="r-truncate"
                        >
                            {item.Title}
                        </Link>
                    </div>
                ))}

                <div className="r-pt-2">
                    {totalHumanyMatches > 10 ? (
                        <Link
                            reloadDocument
                            to={pageLink + `search?phrase=${searchValue}`}
                            className="r-font-semibold r-text-primary-300"
                            onClick={() => setShowMenu(false)}
                        >
                            {localize('search-humany-result-show-more')}
                        </Link>
                    ) : null}
                </div>
            </div>
            <div className="r-space-y-2 r-pt-4">
                <p className="r-pb-2">
                    <SearchResultsText searchValue={searchValue} />
                </p>
                {searchHits?.map((item: SearchResult, index: number) => (
                    <Link
                        onClick={() => setShowMenu(false)}
                        to={formatPermalink(item.permalink)}
                        key={index}
                        className="r-flex r-flex-col r-items-start r-py-4"
                    >
                        <p className="r-pb-1 r-text-sm r-font-bold">{item.post_title}</p>
                        <div className="r-text-sm r-text-gray-400" dangerouslySetInnerHTML={{ __html: item?._formatted.post_content }}></div>
                        <p className="r-pt-2 r-text-xs r-italic r-text-[#008282]">{getFullUrl(item.permalink)}</p>
                    </Link>
                ))}
                {numPages > 1 ? <div className="r-flex r-gap-4">{Array(numPages).join().split(',').map(renderButton)}</div> : null}
            </div>
        </div>
    );
};
