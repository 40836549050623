import { removeLeadingSlash } from './../utils/strings';
export const HUMANY_FAQ_QUERY_KEY = 'humany-faq';

export const humanyFaqQuery = async (widgetSlug: string, path = '', baseUrl: string) => {
    const sanitizedPath = removeLeadingSlash(path).replace('?', '');

    try {
        let url = `https://seo.humany.net/v2/resurs-bank/${widgetSlug}`;
        if (sanitizedPath) {
            url += `/${sanitizedPath}`;
        }

        const urlObject = new URL(url);
        const search = new URLSearchParams(urlObject.search);
        search.append('seoBaseUrl', baseUrl);

        const response = await fetch(`${urlObject.origin}${urlObject.pathname}?${search.toString()}`);

        let markup = '<div>404</div>';

        if (response.status !== 404) {
            markup = await response.text();
        }

        return {
            body: JSON.stringify(markup),
            statusCode: response.status,
        };
    } catch (e: unknown) {
        console.error(e);
        throw e;
    }
};
