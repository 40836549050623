export const resursColors = {
    primary: {
        100: '#26938f',
        200: '#00807b',
        300: '#006464',
        400: '#005050',
        500: '#003C3C',
        600: '#002D2D',
        DEFAULT: '#005050',
    },
    secondary: {
        100: '#F0F000',
        200: '#FFBE00',
        300: '#D28700',
        400: '#C85F00',
        500: '#AA3200',
        DEFAULT: '#D28700',
    },
    //placeholder for now
    accent: {
        100: '#FDB987',
        200: '#FDB987',
        DEFAULT: '#FDB987',
    },
    //placeholder for now
    accentAlt: {
        100: '#FDB987',
        200: '#FDB987',
        DEFAULT: '#FDB987',
    },
    success: {
        400: '#6cc24a',
        DEFAULT: '#6cc24a',
    },
    //placeholder for now
    successAlt: {
        400: '#6cc24a',
        DEFAULT: '#6cc24a',
    },
    warning: {
        400: '#dc2828',
        DEFAULT: '#dc2828',
    },
    danger: {
        400: '#dc2828',
        DEFAULT: '#dc2828',
    },
    //placeholder for now
    disabled: {
        400: '#D0D0D0',
        DEFAULT: '#D0D0D0',
    },
    subdued: {
        100: '#F0F0F0',
        200: '#E0E0E0',
        300: '#D0D0D0',
        400: '#C0C0C0',
        500: '#B0B0B0',
        600: '#A0A0A0',
        700: '#909090',
        800: '#808080',
        900: '#707070',
        DEFAULT: '#B0B0B0',
    },
    focus: {
        DEFAULT: '#FDB987',
    },
};

// @todo figure out how to get these values from tailwind config
const colors = {
    black: '#000000',
    darkGrey: '#2f353d',
    white: '#ffffff',
    almostWhite: '#f8f9fa',
    superLightGrey: '#eeeeee',
    darkWhite: '#e0e0e0',
    lightGrey: '#e0e0e0',
    blue: resursColors.primary['DEFAULT'],
    blueAlt: resursColors.primary[100],
    lightBlue: resursColors.primary[200],
    superLightBlue: 'rgb(0, 215, 180)',
    darkBlue: resursColors.primary['DEFAULT'],
    superDarkBlue: resursColors.primary[600],
    purple: resursColors.secondary['DEFAULT'],
    orange: resursColors.secondary[500],
    peach: resursColors.secondary[400],
    lightPeach: '#fdb987',
    pink: '#ff97ae',
    lightPink: '#f7ced7',
    greenLoan: '#b2d6d3',
    darkest: '#000000',
    grayDarkest: '#121518',
    grayDarker: '#2f353d',
    grayDark: '#4d5057',
    grayAlt: '#646464',
    gray: '#83838f',
    grayLighter: '#9f9f9f',
    grayLightest: '#b1b1b1',
    blueLightest: '#f1f5f9',
    blueLight: '#e6faff',
    lightest: '#ffffff',
    lighter: '#f8f9fa',
    light: '#eeeeee',
    lightAlt: '#e0e0e0',
    transparent: 'transparent',
    primary: resursColors.primary['DEFAULT'],
    primaryAlt: resursColors.primary[100],
    primaryLighter: resursColors.primary[200],
    primaryLightest: 'rgb(0, 215, 180)',
    primaryDarker: resursColors.primary[500],
    primaryDarkest: resursColors.primary[600],
    secondary: resursColors.secondary['DEFAULT'],
    secondaryLighter: resursColors.secondary[200],
    secondaryLightest: resursColors.secondary[100],
    secondaryDarker: resursColors.secondary[400],
    secondaryDarkest: resursColors.secondary[500],
    accent1: resursColors.secondary[500],
    accent2: resursColors.secondary[400],
    accent3: '#fdb987',
    accent4: '#ff97ae',
    accent5: '#f7ced7',
    accent6: '#f8e5eb',
    accent7: '#ec9bba',
    accent8: '#d6ebea',
    accent9: '#f2e9e5',
    accent10: '#bfdfde',
    success: '#6cc24a',
    error: resursColors.danger['DEFAULT'],
    bulletListOrange: '#EB5D2A',
    teal: resursColors.primary[300],
    darkGreen: resursColors.primary[400],
    blueText: '#001489',
};

export function getTailwindColor(key?: string | unknown, property: 'bg' | 'text' | 'border' | 'fill' = 'bg') {
    if (typeof key !== 'string') {
        return null;
    }

    let match = null;

    switch (key) {
        case 'black':
        case 'darkest':
            match = {
                bg: 'r-bg-black',
                text: 'r-text-black',
                border: 'r-border-black',
                fill: 'r-fill-black',
            };
            break;
        case 'darkGrey':
            match = {
                bg: 'r-bg-neutral-700',
                text: 'r-text-neutral-700',
                border: 'r-border-neutral-700',
                fill: 'r-fill-neutral-700',
            };
            break;
        case 'white':
        case 'lightest':
        case '#fff':
        case '#ffffff':
            match = {
                bg: 'r-bg-white',
                text: 'r-text-white',
                border: 'r-border-white',
                fill: 'r-fill-white',
            };
            break;
        case 'almostWhite':
        case 'lighter':
            match = {
                bg: 'r-bg-gray-50',
                text: 'r-text-gray-50',
                border: 'r-border-gray-50',
                fill: 'r-fill-gray-50',
            };
            break;
        case 'superLightGrey':
        case 'superLightGray':
        case 'lightGray':
        case 'lightGrey':
        case 'light':
        case '#eee':
        case '#eeeeee':
            match = {
                bg: 'r-bg-[#eeeeee]',
                text: 'r-text-[#eeeeee]',
                border: 'r-border-[#eeeeee]',
                fill: 'r-fill-[#eeeeee]',
            };
            break;
        case 'darkWhite':
            match = {
                bg: 'r-bg-neutral-200',
                text: 'r-text-neutral-200',
                border: 'r-border-neutral-200',
                fill: 'r-fill-neutral-200',
            };
            break;
        case 'blue':
        case 'darkBlue':
        case 'superDarkBlue':
        case 'purple':
        case 'primary':
            match = {
                bg: 'r-bg-primary',
                text: 'r-text-primary',
                border: 'r-border-primary',
                fill: 'r-fill-primary',
            };
            break;
        case 'blueAlt':
        case 'primaryAlt':
            match = {
                bg: 'r-bg-primary-100',
                text: 'r-text-primary-100',
                border: 'r-border-primary-100',
                fill: 'r-fill-primary-100',
            };
            break;
        case 'lightBlue':
        case 'primaryLighter':
            match = {
                bg: 'r-bg-primary-200',
                text: 'r-text-primary-200',
                border: 'r-border-primary-200',
                fill: 'r-fill-primary-200',
            };
            break;
        case 'teal':
        case 'rgb(0, 100, 100)':
            match = {
                bg: 'r-bg-primary-300',
                text: 'r-text-primary-300',
                border: 'r-border-primary-300',
                fill: 'r-fill-primary-300',
            };
            break;
        case 'peach':
        case 'darkGreen':
            match = {
                bg: 'r-bg-primary-400',
                text: 'r-text-primary-400',
                border: 'r-border-primary-400',
                fill: 'r-fill-primary-400',
            };
            break;
        case 'orange':
        case 'primaryDarker':
            match = {
                bg: 'r-bg-primary-500',
                text: 'r-text-primary-500',
                border: 'r-border-primary-500',
                fill: 'r-fill-primary-500',
            };
            break;
        case 'primaryDarkest':
            match = {
                bg: 'r-bg-primary-600',
                text: 'r-text-primary-600',
                border: 'r-border-primary-600',
                fill: 'r-fill-primary-600',
            };
            break;
        case 'secondary':
            match = {
                bg: 'r-bg-secondary',
                text: 'r-text-secondary',
                border: 'r-border-secondary',
                fill: 'r-fill-secondary',
            };
            break;
        case 'secondaryLighter':
            match = {
                bg: 'r-bg-secondary-200',
                text: 'r-text-secondary-200',
                border: 'r-border-secondary-200',
                fill: 'r-fill-secondary-200',
            };
            break;
        case 'secondaryLightest':
            match = {
                bg: 'r-bg-secondary-100',
                text: 'r-text-secondary-100',
                border: 'r-border-secondary-100',
                fill: 'r-fill-secondary-100',
            };
            break;
        case 'secondaryDarker':
        case 'accent2':
            match = {
                bg: 'r-bg-secondary-400',
                text: 'r-text-secondary-400',
                border: 'r-border-secondary-400',
                fill: 'r-fill-secondary-400',
            };
            break;
        case 'secondaryDarkest':
        case 'accent1':
            match = {
                bg: 'r-bg-secondary-500',
                text: 'r-text-secondary-500',
                border: 'r-border-secondary-500',
                fill: 'r-fill-secondary-500',
            };
            break;
        case 'superLightBlue':
        case 'primaryLightest':
            match = {
                bg: 'r-bg-[#00D7B4]',
                text: 'r-text-[#00D7B4]',
                border: 'r-border-[#00D7B4]',
                fill: 'r-fill-[#00D7B4]',
            };
            break;
        case 'lightPeach':
            match = {
                bg: 'r-bg-[#fdb987]',
                text: 'r-text-[#fdb987]',
                border: 'r-border-[#fdb987]',
                fill: 'r-fill-[#fdb987]',
            };
            break;
        case 'pink':
            match = {
                bg: 'r-bg-[#ff97ae]',
                text: 'r-text-[#ff97ae]',
                border: 'r-border-[#ff97ae]',
                fill: 'r-fill-[#ff97ae]',
            };
            break;
        case 'lightPink':
            match = {
                bg: 'r-bg-[#f7ced7]',
                text: 'r-text-[#f7ced7]',
                border: 'r-border-[#f7ced7]',
                fill: 'r-fill-[#f7ced7]',
            };
            break;
        case 'greenLoan':
            match = {
                bg: 'r-bg-[#b2d6d3]',
                text: 'r-text-[#b2d6d3]',
                border: 'r-border-[#b2d6d3]',
                fill: 'r-fill-[#b2d6d3]',
            };
            break;
        case 'grayDarkest':
            match = {
                bg: 'r-bg-neutral-900',
                text: 'r-text-neutral-900',
                border: 'r-border-neutral-900',
                fill: 'r-fill-neutral-900',
            };
            break;
        case 'grayDarker':
            match = {
                bg: 'r-bg-[#2f353d]',
                text: 'r-text-[#2f353d]',
                border: 'r-border-[#2f353d]',
                fill: 'r-fill-[#2f353d]',
            };
            break;
        case 'grayDark':
            match = {
                bg: 'r-bg-zinc-600',
                text: 'r-text-zinc-600',
                border: 'r-border-zinc-600',
                fill: 'r-fill-zinc-600',
            };
            break;
        case 'grayAlt':
            match = {
                bg: 'r-bg-[#646464]',
                text: 'r-text-[#646464]',
                border: 'r-border-[#646464]',
                fill: 'r-fill-[#646464]',
            };
            break;
        case 'gray':
            match = {
                bg: 'r-bg-[#83838f]',
                text: 'r-text-[#83838f]',
                border: 'r-border-[#83838f]',
                fill: 'r-fill-[#83838f]',
            };
            break;
        case 'grayLighter':
            match = {
                bg: 'r-bg-neutral-400',
                text: 'r-text-neutral-400',
                border: 'r-border-neutral-400',
                fill: 'r-fill-neutral-400',
            };
            break;
        case 'grayLightest':
            match = {
                bg: 'r-bg-[#b1b1b1]',
                text: 'r-text-[#b1b1b1]',
                border: 'r-border-[#b1b1b1]',
                fill: 'r-fill-[#b1b1b1]',
            };
            break;
        case 'blueLightest':
            match = {
                bg: 'r-bg-slate-100',
                text: 'r-text-slate-100',
                border: 'r-border-slate-100',
                fill: 'r-fill-slate-100',
            };
            break;
        case 'blueLight':
            match = {
                bg: 'r-bg-[#e6faff]',
                text: 'r-text-[#e6faff]',
                border: 'r-border-[#e6faff]',
                fill: 'r-fill-[#e6faff]',
            };
            break;
        case 'lightAlt':
            match = {
                bg: 'r-bg-neutral-200',
                text: 'r-text-neutral-200',
                border: 'r-border-neutral-200',
                fill: 'r-fill-neutral-200',
            };
            break;
        case 'transparent':
            match = {
                bg: 'r-bg-transparent',
                text: 'r-text-transparent',
                border: 'r-border-transparent',
                fill: 'r-fill-transparent',
            };
            break;
        case 'accent3':
            match = {
                bg: 'r-bg-[#fdb987]',
                text: 'r-text-[#fdb987]',
                border: 'r-border-[#fdb987]',
                fill: 'r-fill-[#fdb987]',
            };
            break;
        case 'accent4':
            match = {
                bg: 'r-bg-[#ff97ae]',
                text: 'r-text-[#ff97ae]',
                border: 'r-border-[#ff97ae]',
                fill: 'r-fill-[#ff97ae]',
            };
            break;
        case 'accent5':
            match = {
                bg: 'r-bg-[#f7ced7]',
                text: 'r-text-[#f7ced7]',
                border: 'r-border-[#f7ced7]',
                fill: 'r-fill-[#f7ced7]',
            };
            break;
        case 'accent6':
            match = {
                bg: 'r-bg-[#f8e5eb]',
                text: 'r-text-[#f8e5eb]',
                border: 'r-border-[#f8e5eb]',
                fill: 'r-fill-[#f8e5eb]',
            };
            break;
        case 'accent7':
            match = {
                bg: 'r-bg-[#ec9bba]',
                text: 'r-text-[#ec9bba]',
                border: 'r-border-[#ec9bba]',
                fill: 'r-fill-[#ec9bba]',
            };
            break;
        case 'accent8':
            match = {
                bg: 'r-bg-[#d6ebea]',
                text: 'r-text-[#d6ebea]',
                border: 'r-border-[#d6ebea]',
                fill: 'r-fill-[#d6ebea]',
            };
            break;
        case 'accent9':
            match = {
                bg: 'r-bg-[#f2e9e5]',
                text: 'r-text-[#f2e9e5]',
                border: 'r-border-[#f2e9e5]',
                fill: 'r-fill-[#f2e9e5]',
            };
            break;
        case 'accent10':
            match = {
                bg: 'r-bg-[#bfdfde]',
                text: 'r-text-[#bfdfde]',
                border: 'r-border-[#bfdfde]',
                fill: 'r-fill-[#bfdfde]',
            };
            break;
        case 'success':
            match = {
                bg: 'r-bg-[#6cc24a]',
                text: 'r-text-[#6cc24a]',
                border: 'r-border-[#6cc24a]',
                fill: 'r-fill-[#6cc24a]',
            };
            break;
        case 'error':
            match = {
                bg: 'r-bg-error',
                text: 'r-text-error',
                border: 'r-border-error',
                fill: 'r-fill-error',
            };
            break;
        case 'bulletListOrange':
            match = {
                bg: 'r-bg-orange-600',
                text: 'r-text-orange-600',
                border: 'r-border-orange-600',
                fill: 'r-fill-orange-600',
            };
            break;
        case 'blueText':
            match = {
                bg: 'r-bg-blue-900',
                text: 'r-text-blue-900',
                border: 'r-border-blue-900',
                fill: 'r-fill-blue-900',
            };
            break;
    }

    if (!match || !property) {
        return null;
    }

    return match[property] ?? null;
}

export const mapColor = (key?: string) => colors[key as keyof typeof colors] ?? null;

export const hexToRgba = (hexCode: string, opacity = 1) => {
    if (hexCode === 'transparent') {
        return 'transparent';
    }

    if (hexCode === 'white') {
        hexCode = '#ffffff';
    }
    if (hexCode === 'black') {
        hexCode = '#000000';
    }

    let hex = hexCode.replace('#', '');

    if (hex.length === 3) {
        hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
    }

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    /* Backward compatibility for whole number based opacity values. */
    if (opacity > 1 && opacity <= 100) {
        opacity = opacity / 100;
    }

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};
