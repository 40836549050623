import { ApiFetchType } from '../server/api-fetch';
import { AllContactMeFormsProps } from '@/isomorphic/components/wp-blocks/contact-form/contact-form';
import { AllTermsProps } from '@/isomorphic/components/wp-blocks/insights/components/all-terms';
import { ArticleCardProps } from '@/isomorphic/components/wp-blocks/article-card/article-card';
import { ArticleCardsProps } from '@/isomorphic/components/wp-blocks/article-cards/article-cards';
import { BankOpenSavingsFormBusinessProps } from '@/isomorphic/components/wp-blocks/bank-open-savings-form-business/bank-open-savings-form-business';
import { BankOpenSavingsFormProps } from '@/isomorphic/components/wp-blocks/bank-open-savings-form/types';
import { BusinessLoanApplicationProps } from '@/isomorphic/components/wp-blocks/business-loan-application/business-loan-application';
import { ButtonProps } from '@/isomorphic/components/wp-blocks/button/resurs-button';
import { CardCollapseProps } from '@/isomorphic/components/wp-blocks/card-collapse/card-collapse';
import { CardComparisonProps } from '@/isomorphic/components/wp-blocks/card-comparison/card-comparison';
import { CardComparisonLegacyProps } from '@/isomorphic/components/wp-blocks/card-comparison-legacy/card-comparison-legacy';
import { HeroProps } from '@/isomorphic/components/wp-blocks/hero/hero';
import { CardDetailsProps } from '@/isomorphic/components/wp-blocks/card-details/card-details';
import { CardOverviewProps } from '@/isomorphic/components/wp-blocks/card-overview/card-overview';
import { CisionLandingProps } from '@/isomorphic/components/wp-blocks/cision-landing/cision-landing';
import { CisionProps } from '@/isomorphic/components/wp-blocks/cision/cision';
import { CisionSingleItemProps } from '@/isomorphic/components/wp-blocks/cision-single-item/cision-single-item';
import { ClickableInfoboxesProps } from '@/isomorphic/components/wp-blocks/clickable-infoboxes/clickable-infoboxes';
import { ClusterCalculatorProps } from '@/isomorphic/components/wp-blocks/cluster-calculator/cluster-calculator';
import { ColumnProps } from '@/isomorphic/components/layout/column/column';
import { ConsumerloanCalculatorAppProps } from '@/isomorphic/components/wp-blocks/consumerloan-calculator-app/consumerloan-calculator-app';
import { ConsumerLoanCounterModuleProps } from '@/isomorphic/components/wp-blocks/consumerloan-counter-module/consumerloan-counter-module';
import { DecoratedData } from './../hooks/use-ab-tests';
import { DocumentHandlerProps } from '@/isomorphic/components/document-handler/document-handler';
import { DownloadModuleProps } from '@/isomorphic/components/wp-blocks/download-module/download-module';
import { FaqClusterProps } from '@/isomorphic/components/wp-blocks/faq-cluster/faq-cluster';
import { GiftCardBalanceProps } from '@/isomorphic/components/wp-blocks/gift-card-balance/gift-card-balance';
import { GridLayoutContainerProps } from '@/isomorphic/components/wp-blocks/grid-layout-container/grid-layout-container';
import { HeadlineProps } from '@/isomorphic/components/typography/headline/headline';
import { IFrameProps } from '@/isomorphic/components/iframe/iframe';
import { InsightProps } from '@/isomorphic/components/wp-blocks/insights/insight';
import { ListProps } from '@/isomorphic/components/typography/list/list';
import { MyEconomyProps } from '@/isomorphic/components/wp-blocks/my-economy/my-economy';
import { OneWidgetFaqProps } from '@/isomorphic/components/wp-blocks/one-widget-faq/one-widget-faq';
import { ParagraphProps } from '@/isomorphic/components/typography/paragraph/paragraph';
import { RateCalculatorVerticalProps } from '@/isomorphic/components/wp-blocks/rate-calculator-vertical/rate-calculator-vertical';
import { RelatedPostsProps } from '@/isomorphic/components/wp-blocks/insights/components/related-posts';
import { removeLeadingSlash } from '@/utils/strings';
import { ResursImageProps } from '@/isomorphic/components/wp-blocks/image/resurs-image';
import { SavingsCalculatorProps } from '@/isomorphic/components/wp-blocks/savings-calculator/savings-calculator';
import { SearchProps } from '@/isomorphic/components/wp-blocks/insights/components/search';
import { SearchSortProps } from '@/isomorphic/components/wp-blocks/insights/components/search-sort';
import { SectionProps } from '@/isomorphic/components/layout/section/section';
import { ShortcutsProps } from '@/isomorphic/components/wp-blocks/shortcuts/shortcuts';
import { SpacerProps } from '@/isomorphic/components/typography/spacer/spacer';
import { SupremeCardApplicationProps } from '@/isomorphic/components/wp-blocks/supreme-card-application/types';
import { SupremeCardInfoProps } from '@/isomorphic/components/wp-blocks/supreme-card-info/supreme-card-info';
import { SupremeCardNewProps } from '@/isomorphic/components/wp-blocks/supreme-card-new/supreme-card-new';
import { TableProps } from '@/isomorphic/components/wp-blocks/table/table';
import { VideoProps } from '@/isomorphic/components/video/video';
import { WaveContentProps } from '@/isomorphic/components/wp-blocks/wave-content/wave-content';
import { JulaAccountVerificationProps } from '@/isomorphic/components/wp-blocks/jula-account-verification/jula-account-verification';
import { LoginScreenProps } from '@/isomorphic/components/wp-blocks/login-screen/login-screen';
import { LegilativeCouncilReferralProps } from '@/isomorphic/components/wp-blocks/legilative-council-referral/legilative-council-referral';

export const PAGE_QUERY_KEY = 'page';

export type BlockComponentProps =
    | AllContactMeFormsProps
    | AllTermsProps
    | ArticleCardProps
    | ArticleCardsProps
    | BankOpenSavingsFormBusinessProps
    | BankOpenSavingsFormProps
    | BusinessLoanApplicationProps
    | ButtonProps
    | CardCollapseProps
    | CardComparisonProps
    | CardComparisonLegacyProps
    | CardDetailsProps
    | CardOverviewProps
    | CisionLandingProps
    | CisionProps
    | CisionSingleItemProps
    | IFrameProps
    | VideoProps
    | ClickableInfoboxesProps
    | ClusterCalculatorProps
    | ColumnProps
    | ConsumerloanCalculatorAppProps
    | ConsumerLoanCounterModuleProps
    | DocumentHandlerProps
    | DownloadModuleProps
    | FaqClusterProps
    | GiftCardBalanceProps
    | GridLayoutContainerProps
    | HeadlineProps
    | HeroProps
    | IFrameProps
    | InsightProps
    | JulaAccountVerificationProps
    | ListProps
    | LoginScreenProps
    | MyEconomyProps
    | OneWidgetFaqProps
    | ParagraphProps
    | RateCalculatorVerticalProps
    | RelatedPostsProps
    | ResursImageProps
    | SavingsCalculatorProps
    | SearchProps
    | SearchSortProps
    | SectionProps
    | ShortcutsProps
    | SpacerProps
    | SupremeCardApplicationProps
    | SupremeCardInfoProps
    | SupremeCardNewProps
    | TableProps
    | VideoProps
    | WaveContentProps
    | LegilativeCouncilReferralProps;

export type BlockBase = BlockComponentProps & {
    blockName: string;
    index: number;
    blocks?: BlockBase[];
    innerBlocks?: BlockBase[];
    contentInnerBlocks?: BlockBase[];
    parentProps?: BlockComponentProps;
};

export type MarketType = 'private' | 'corporate' | 'cluster';

export type PageType = {
    id: number;
    post_title: string;
    permalink: string;
    guid: string;
    title_type: string;
    custom_title: null | string;
    breadcrumb_title_type: string;
    custom_breadcrumb_title: null | string;
    back_button: string;
    ancestors: PageType[];
    market_type?: MarketType | null;
    parent_market_type?: MarketType;
    remove_header: boolean;
    remove_footer: boolean;
    canonical_url: string;
    banner_cta_link?: {
        title: string;
        url: string;
        target: string;
    };
    banner_cta_text?: string;
    banner_enabled?: boolean;
    banner_title?: string;
    contact_me_form?: {
        button_text: string;
        email_title: string;
        form_fields: {
            acf_fc_layout: string;
            label: string;
            placeholder: string;
            required: string[];
            validation_method: string;
        }[];
        form_gtm?: string;
        form_id: string;
        form_subtitle: string;
        form_title: string;
        send_to: string;
        submit_form_text: string;
        thank_you_text: string;
    };
    page_title: string;
    meta_description: string;
    meta_title?: string;
    og_title: string;
    og_image: {
        src: string;
        alt_text: string;
        originalSize: {
            width: number;
            height: number;
        };
        focus: {
            x: number;
            y: number;
        };
        image_id: number;
        aws_bucket: string;
    };
    og_description: string;
    no_index: boolean;
    page_background_color: string;
    redirect_to_page: null | string;
    show_ai_chatbot: boolean;
    hide_ai_chatbot?: boolean;
    exclude_from_search: boolean;
    exclude_from_sitemap: boolean;
    iframe_token: boolean;
    blocks: BlockBase[];
    sites_allowed_iframe?: string;
    featured_image?: {
        src: string;
        alt_text: string;
        originalSize: {
            width: number;
            height: number;
        };
        focus: {
            x: number;
            y: number;
        };
        image_id: number;
    };
    __meta: {
        source: string;
        root: string;
        parentId: string;
        externalId: string;
        documentType: string;
    };
    useMyEconomyDrawer: boolean;
    decorated: DecoratedData;
};

export const getPage = async (path: string, context: ApiFetchType, { humanyPageLink = '', humanyPageLinkBusiness = '' }) => {
    let slug = removeLeadingSlash(path);
    if (!context.fetch) {
        return null;
    }

    if (humanyPageLink) {
        const oneWidgetRegexp = new RegExp(`^${humanyPageLink}(?![_-a-z])`, 'g');
        if (oneWidgetRegexp.test(`/${slug}`)) {
            slug = removeLeadingSlash(humanyPageLink);
        }
    }

    if (humanyPageLinkBusiness) {
        const oneWidgetBusinessRegexp = new RegExp(`^${humanyPageLinkBusiness}(?![_-a-z])`, 'g');
        if (oneWidgetBusinessRegexp.test(`/${slug}`)) {
            slug = removeLeadingSlash(humanyPageLinkBusiness);
        }
    }

    const response = await context.fetch<PageType>(`api/page/${slug}`);

    if (!response) {
        return context.fetch<PageType>('api/page/system/404');
    }

    return response;
};
