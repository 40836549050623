import { FaqItem } from './components/faq-item';

export type FaqClusterItem = {
    question: string;
    answer: TrustedHTML;
};

export type FaqClusterProps = {
    questions: FaqClusterItem[];
    labels?: {
        headingText: string;
    };
    hideTitle?: boolean;
};

export function FaqCluster({ questions, labels, hideTitle = false }: FaqClusterProps) {
    if (!questions.length) {
        return null;
    }

    const base = {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: questions.map(({ question, answer }) => ({
            '@type': 'Question',
            name: question,
            acceptedAnswer: {
                '@type': 'Answer',
                text: answer,
            },
        })),
    };

    const showTitle = () => !hideTitle && labels?.headingText;

    return (
        <div data-component="faq-cluster">
            <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(base) }} />
            {showTitle() ? <h3 className="r-pb-4 r-text-3xl r-font-bold" dangerouslySetInnerHTML={{ __html: labels?.headingText || '' }} /> : null}
            <dl>
                {questions.map((props, index) => (
                    <FaqItem {...props} key={index} index={index + 1} />
                ))}
            </dl>
        </div>
    );
}
