import { getTailwindColor } from '@/utils/colors';
import imageSrc from './warning.svg';
import clsx from 'clsx';

export type LegilativeCouncilReferralProps = {
    header: string;
    text: string;
    bgColor: string;
};

export const LegilativeCouncilReferral = ({ header, text, bgColor }: LegilativeCouncilReferralProps) => (
    <div data-component="legilative-council-referral" className={clsx(getTailwindColor(bgColor, 'bg'), '')}>
        <div className="r-container r-p-4 sm:r-p-8">
            <div className="r-rounded-2xl r-bg-white r-p-6 r-shadow-md lg:r-flex lg:r-items-center lg:r-gap-6 lg:r-p-8">
                <div className="r-items-end r-gap-4 max-lg:r-flex max-lg:r-pb-3 md:r-gap-6">
                    <img className="r-w-20 lg:r-w-44" src={imageSrc} />
                    <h4 className="r-font-bold lg:r-hidden">{header}</h4>
                </div>
                <div>
                    <h4 className="r-font-bold max-lg:r-hidden">{header}</h4>
                    <div className="styled-link r-text- r-space-y-4" dangerouslySetInnerHTML={{ __html: text }} />
                </div>
            </div>
        </div>
    </div>
);
